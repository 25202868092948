import {
  SuperMoonApplyAvailableStatus,
  type Profile,
  type SigninPayload,
  useAppStore,
  useSuperMoonStore,
  type SuperMoonApplyStatus,
} from '@store'
import { ROUTES } from '@configs'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const mypageStore = useMyPageStore()
  const appStore = useAppStore()
  const superMoonStore = useSuperMoonStore()
  const { cookieNames, getCookie } = useCookies()
  const { isApp } = storeToRefs(appStore)
  let userInfoInCookie: SigninPayload | null = null
  let profile: Profile | null = null
  let status: SuperMoonApplyStatus | null = null
  // 1. 사용자 조건을 파악하기 위해 쿠키 접근(SERVER, CLIENT)
  if (process.server) {
    const { cookie } = useRequestHeaders(['cookie'])
    userInfoInCookie = getCookie<SigninPayload>(
      cookieNames.USER_INFO,
      cookie || ''
    )
  } else {
    userInfoInCookie = getCookie<SigninPayload>(cookieNames.USER_INFO)
  }
  // 2. 쿠키에 로그인 정보가 존재 한다면 사용자 프로필(profile) 조회
  // 시설물 임대 페이지들 중 로그인이 필수 혹은 필수가 아닌 페이지가 존재하여 따로 에러 헨들링 하지 않고 상태값으로 랜딩 조건 지정
  if (userInfoInCookie) {
    const { data: _profile } = await useAsyncData('profile', () =>
      mypageStore.fetchProfile()
    )
    profile = _profile.value
  }
  // 비 로그인 상태에서 라우트가드 무한 동작 방지용 조건문
  if (
    (from.fullPath === ROUTES.APPLY_RENT.path && !profile) ||
    (to.fullPath === ROUTES.APPLY_RENT.path && !profile)
  ) {
    return
  }
  // 3. 슈퍼문 신청 요건 정보 조회
  const { data: _status } = await useAsyncData('superMoonStatus', () =>
    superMoonStore.fetchSuperMoonApplyStatus()
  )
  status = _status.value
  const superMoonAvailableStatus = () => {
    // 1. 앱 여부 판단
    if (!isApp.value) {
      return SuperMoonApplyAvailableStatus.UNAVAILABLE_NOT_APP
    }
    // 2. 로그인 여부 판단
    if (!profile) {
      return SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
    }
    // 3. 슈퍼문 참가 신청 가능 여부 판단
    if (!status) {
      return SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
    }
    if (status.fstvlApprtcAt === 'Y') {
      return SuperMoonApplyAvailableStatus.UNAVAILABLE_ALREADY_APPLIED
    }
    // 4. 서버에서 판단해서 보내주는 신청 불가
    if (status.applicationAvailableAt === 'N') {
      return SuperMoonApplyAvailableStatus.UNAVAILABLE_APPLIED
    }
    // 5. 신청 가능
    return SuperMoonApplyAvailableStatus.AVAILABLE_APPLY
  }
  // 6. superMoonAvailableStatus 따라 라우트 이동
  switch (superMoonAvailableStatus()) {
    case SuperMoonApplyAvailableStatus.UNAVAILABLE_NOT_APP:
    case SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS:
    case SuperMoonApplyAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN:
    case SuperMoonApplyAvailableStatus.UNAVAILABLE_ALREADY_APPLIED:
    case SuperMoonApplyAvailableStatus.UNAVAILABLE_APPLIED:
      await useNavigations({
        url: ROUTES.APPLY_SUPER_MOON.path,
      })
      break
    case SuperMoonApplyAvailableStatus.AVAILABLE_APPLY:
      break
    default:
      throw showError({
        statusCode: 500,
        statusMessage: 'check conditions error in middleware rent guard',
      })
  }
})
